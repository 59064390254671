import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import Home from "./pages/Home";
import ResultDetails from "./components/ResultDetails";
import ResultSummery from "./components/ResultSummery";

const theme = createTheme({
  typography: {
    fontFamily: "Roboto, sans-serif",
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Router>
          <AppBar component="nav">
            <Toolbar sx={{ justifyContent: "center" }}>
              <Box sx={{ flexGrow: 1 }} />
              <Typography variant={"h5"} align="center" sx={{ my: 2 }}>
                Vision Detection Dashboard
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
            </Toolbar>
          </AppBar>

          <Toolbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/resultsummery" element={<ResultSummery />} />
            <Route path="/resultdetails" element={<ResultDetails />} />
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
