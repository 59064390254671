import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
} from "@mui/material";

interface ClassIDConfigProps {
  classIDConfig: string;
  setClassIDConfig: React.Dispatch<React.SetStateAction<string>>;
  setParsedClassIDs: (parsedClassIDs: { [key: number]: string }) => void;
}

const ClassIDConfig: React.FC<ClassIDConfigProps> = ({
  classIDConfig,
  setClassIDConfig,
  setParsedClassIDs,
}) => {
  // const [classIDs, setClassIDs] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const handleSave = () => {
    if (classIDConfig.trim() === "") {
      setClassIDConfig("");
      setParsedClassIDs({});
      setError(null);
      return;
    }

    try {
      const parsedClassIDs = JSON.parse(classIDConfig);
      if (typeof parsedClassIDs !== "object" || parsedClassIDs === null) {
        throw new Error("Invalid JSON structure.");
      }

      // Validate that each key is a number and each value is a string
      const isValid = Object.entries(parsedClassIDs).every(
        ([key, value]) => !isNaN(Number(key)) && typeof value === "string"
      );

      if (!isValid) {
        throw new Error(
          "Each key should be a number and each value should be a string."
        );
      }

      setParsedClassIDs(parsedClassIDs);
      setError(null); // Clear any previous errors
    } catch (err) {
      setError((err as Error).message);
    }
  };

  return (
    <Card sx={{ p: 3 }}>
      <Typography variant="h5" fontWeight="bold" mb={2}>
        Class ID Configuration
      </Typography>
      <div style={{ marginBottom: "1rem" }}>
        <TextField
          id="class-id-input"
          label="Class ID Names"
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          value={classIDConfig}
          onChange={(e) => setClassIDConfig(e.target.value)}
          placeholder='Enter JSON, e.g., {"0": "Car", "1": "Truck"}'
          // {"0": "Blue", "1": "Orange", "2": "Green"}
          error={Boolean(error)}
          helperText={error}
        />
      </div>
      <Button
        id="save-class-ids"
        variant="contained"
        color="primary"
        onClick={handleSave}
      >
        Save Class IDs
      </Button>
    </Card>
  );
};

export default ClassIDConfig;
