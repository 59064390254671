import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Button,
} from "@mui/material";

import axios, { AxiosResponse } from "axios";

import ImageViewDetails from "./ImageViewDetails";

const ResultDetails: React.FC = () => {
  const location = useLocation();
  const { selectedImage, classIds, rois } = location.state || {};

  useEffect(() => {}, []);

  return (
    <div>
      <ImageViewDetails
        selectedImage={selectedImage}
        rois={rois}
        classIds={classIds}
      />
    </div>
  );
};

export default ResultDetails;
