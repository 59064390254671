import React, { useState, useMemo } from "react";
import {
  Card,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

interface Object {
  id: number;
  classId: number;
  class: string;
  confLevel: number;
  word: string;
  roi: number[];
}

const DetectionResults: React.FC<{
  parsedClassIDs: Record<number, string>;
  classIds: number[] | null;
  confLevels: number[] | null;
  items: Object[] | null;
}> = ({ parsedClassIDs, classIds, confLevels, items }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const results = useMemo(() => {
    if (!classIds || !confLevels) return [];

    const resultMap = new Map<
      number,
      { count: number; totalConfidence: number }
    >();

    classIds.forEach((classId, index) => {
      if (!resultMap.has(classId)) {
        resultMap.set(classId, { count: 0, totalConfidence: 0 });
      }
      const entry = resultMap.get(classId)!;
      entry.count += 1;
      entry.totalConfidence += confLevels[index];
    });

    return Array.from(resultMap.entries())
      .map(([classId, { count, totalConfidence }]) => ({
        name: parsedClassIDs[classId] || classId.toString(),
        count,
        confidenceLevel: totalConfidence / count,
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [parsedClassIDs, classIds, confLevels]);

  return (
    <Card sx={{ padding: "24px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" fontWeight="bold" mb={2}>
          Detection Results
        </Typography>
        <Button variant="contained" onClick={handleOpen}>
          View Details
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table className="result-table" aria-label="detection results">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Count</TableCell>
              <TableCell>Confidence Level</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map((result, index) => (
              <TableRow key={index}>
                <TableCell>{result.name}</TableCell>
                <TableCell>{result.count}</TableCell>
                <TableCell>{result.confidenceLevel.toFixed(2)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Detailed Detection Results</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table aria-label="detailed results">
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Class</TableCell>
                  <TableCell>Confidence Level</TableCell>
                  <TableCell>Word</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.id}</TableCell>
                    <TableCell>
                      {parsedClassIDs[item.classId] || "No Class"}
                    </TableCell>
                    <TableCell>{item.confLevel.toFixed(2)}</TableCell>
                    <TableCell>{item.word || "No OCR"}</TableCell>
                  </TableRow>
                )) || (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default DetectionResults;
