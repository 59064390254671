import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

interface JsonDialogBoxProps {
  open: boolean;
  onClose: () => void;
  jsonString: string;
}

const JsonDialogBox: React.FC<JsonDialogBoxProps> = ({
  open,
  onClose,
  jsonString,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      scroll="paper"
      PaperProps={{ style: { height: "100%" } }}
    >
      <DialogTitle>JSON Data</DialogTitle>
      <DialogContent dividers>
        <Typography
          component="pre"
          variant="body2"
          style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
        >
          {jsonString}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default JsonDialogBox;
