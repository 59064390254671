import React, { useState, useRef, useEffect } from "react";
import axios, { AxiosResponse } from "axios";
import Cookies from "js-cookie";
import {
  Typography,
  Button,
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  createTheme,
} from "@mui/material";
import CropIcon from "@mui/icons-material/Crop";
import RoiSelection from "../common/RoiSelection";
import CameraCapture from "../common/CameraCapture";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// import ImageUpload from "../common/ImageUpload"; // Adjust the path if necessary

interface Project {
  project_id: number;
  project_name: string;
}

interface ImageProcessProps {
  rois: number[][] | null;
  classIds: number[] | null;
  croppedImageFile: File | null;
  setCroppedImageFile: React.Dispatch<React.SetStateAction<File | null>>;
  clearData: () => void;
}

const theme = createTheme();

const ImageProcess: React.FC<ImageProcessProps> = ({
  rois,
  classIds,
  croppedImageFile,
  setCroppedImageFile,
  clearData,
}) => {
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const [openImageDialog, setOpenImageDialog] = useState(false);

  const [selectedImageFile, setSelectedImageFile] = useState<File | null>(null);
  const [selectedImageURL, setSelectedImageURL] = useState<string>("");
  const [croppedImageURL, setCroppedImageURL] = useState<string | null>(null);

  const [roiProjects, setRoiProjects] = useState<Project[]>([]);
  const [roiProjectName, setRoiProjectName] = useState<string>("");
  const [roiProjectID, setRoiProjectID] = useState<string>("");

  const [roi, setRoi] = useState<[number, number, number, number] | null>(null);

  const imageRef = useRef<HTMLImageElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    axios
      .get<{ data: Project[] }>("/sol_server/project/all_projects")
      .then((response: AxiosResponse<{ data: Project[] }>) => {
        console.log(response.data);
        setRoiProjects(response.data.data);
        setRoiProjectName("Test_Nesspresso");
        const infProject = response.data.data.find(
          (project) => project.project_name === "Test_Nesspresso"
        );
        if (infProject) {
          setRoiProjectID(infProject.project_id.toString());
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  useEffect(() => {
    const roiCookie = Cookies.get("roi");
    if (roiCookie) {
      setRoi(JSON.parse(roiCookie));
    }
  }, []);

  useEffect(() => {
    if (roi) {
      Cookies.set("roi", JSON.stringify(roi), { expires: 7 });
    }
  }, [roi]);

  // Drawing on Canvas
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");
    const img = new Image();
    img.src = croppedImageURL
      ? croppedImageURL
      : "https://via.placeholder.com/800x400";

    img.onload = () => {
      if (canvas && ctx) {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight * 0.3;

        const imgAspectRatio = img.width / img.height;
        const windowAspectRatio = windowWidth / windowHeight;

        let canvasWidth: number;
        let canvasHeight: number;

        if (windowAspectRatio > imgAspectRatio) {
          canvasHeight = windowHeight;
          canvasWidth = windowHeight * imgAspectRatio;
        } else {
          canvasWidth = windowWidth;
          canvasHeight = windowWidth / imgAspectRatio;
        }

        // Set the canvas size
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        ctx.strokeStyle = "red";
        ctx.lineWidth = 2;
        ctx.font = "15px Arial";
        ctx.textAlign = "left";
        ctx.textBaseline = "top";

        if (rois) {
          const scaleX = canvas.width / img.width;
          const scaleY = canvas.height / img.height;
          rois.forEach((roi, index) => {
            const [topLeftX, topLeftY, bottomRightX, bottomRightY] = roi;
            const width = bottomRightX - topLeftX;
            const height = bottomRightY - topLeftY;

            ctx.strokeRect(
              topLeftX * scaleX,
              topLeftY * scaleY,
              width * scaleX,
              height * scaleY
            );

            if (classIds) {
              const id_string = classIds[index].toString();
              const text = `${id_string}`;

              const textMetrics = ctx.measureText(text);
              const textWidth = textMetrics.width;
              const textHeight = 15;

              ctx.fillStyle = "red";
              ctx.fillRect(
                topLeftX * scaleX,
                topLeftY * scaleY,
                textWidth,
                textHeight
              );

              ctx.fillStyle = "white";
              ctx.fillText(text, topLeftX * scaleX, topLeftY * scaleY);
            }
          });
        }
      }
    };
  }, [croppedImageURL, rois, classIds]);

  useEffect(() => {
    if (roi) {
      const canvas = document.createElement("canvas");
      canvas.width = roi[2];
      canvas.height = roi[3];
      const ctx = canvas.getContext("2d");

      const image = new Image();
      image.src = selectedImageURL;
      image.onload = () => {
        console.log("Image", image.width, image.height);
        if (ctx) {
          ctx.drawImage(
            image,
            roi[0],
            roi[1],
            roi[2],
            roi[3],
            0,
            0,
            roi[2],
            roi[3]
          );
          canvas.toBlob((blob) => {
            if (blob) {
              const file = new File([blob], "croppedImage.jpg", {
                type: "image/jpeg",
              });
              setCroppedImageFile(file);
            } else {
              setCroppedImageFile(null);
            }
          }, "image/jpeg");
          setCroppedImageURL(canvas.toDataURL("image/png"));
        }
      };
    } else {
      setCroppedImageFile(selectedImageFile);
      setCroppedImageURL(selectedImageURL);
    }
  }, [selectedImageFile, roi]);

  const handleImageCapture = (imageUrl: string) => {
    setSelectedImageURL(imageUrl);
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const file = new File([blob], "captured.png", { type: blob.type });
        setSelectedImageFile(file);
      })
      .catch((error) => {
        console.error("Error fetching the image", error);
      });
    clearData();
  };

  const handleRoiSelected = (roi: [number, number, number, number] | null) => {
    setRoi(roi);
    clearData();
  };

  // Upload handler using the separate ImageUpload component
  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      var image_url = URL.createObjectURL(event.target.files[0]);
      setSelectedImageURL(image_url);
      setSelectedImageFile(event.target.files[0]);
      clearData();
    }
  };

  return (
    <div>
      <Typography variant="h5" fontWeight="bold" mb={2}>
        Image Upload & ROI
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={4} lg={4}>
          <CameraCapture
            onImageChange={handleImageCapture}
            onRoiSelected={handleRoiSelected}
            initialRoi={roi ?? undefined}
          />
        </Grid>

        <Grid item xs={4} lg={4}>
          <Box>
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="select-button-file"
              type="file"
              onChange={handleImageUpload}
            />
            <label htmlFor="select-button-file">
              <Button variant="contained" color="primary" component="span">
                <CloudUploadIcon />
              </Button>
            </label>
          </Box>
        </Grid>

        <Grid item xs={4} lg={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenImageDialog(true)}
          >
            <CropIcon />
          </Button>
        </Grid>
      </Grid>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          mt: 2,
          mb: 2,
        }}
      >
        <canvas
          ref={canvasRef}
          style={{
            border: "1px solid #ddd",
            display: "block",
            width: "100%",
          }}
        />
      </Box>

      {/* Dialog for ROI Selection */}
      <Dialog
        open={openImageDialog}
        onClose={() => setOpenImageDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Select ROI</DialogTitle>
        <DialogContent>
          <RoiSelection
            src={
              selectedImageURL
                ? selectedImageURL
                : "https://via.placeholder.com/800x400"
            }
            onRoiSelected={handleRoiSelected}
            initialRoi={roi ?? undefined}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenImageDialog(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ImageProcess;
