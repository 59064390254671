import { useState, useEffect } from "react";
import { QueryBuilderDnD } from "@react-querybuilder/dnd";
import * as ReactDnD from "react-dnd";
import * as ReactDndHtml5Backend from "react-dnd-html5-backend";
import type { RuleGroupType } from "react-querybuilder";
import { QueryBuilder } from "react-querybuilder";
import type { Field, RuleType } from "react-querybuilder";
import { defaultOperators, toFullOption } from "react-querybuilder";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { QueryBuilderMaterial } from "@react-querybuilder/material";

import "react-querybuilder/dist/query-builder.scss";
import "./styles.scss";

const muiTheme = createTheme();

interface LogicBuilderProps {
  query: RuleGroupType;
  setQuery: React.Dispatch<React.SetStateAction<RuleGroupType>>;
  parsedClassIDs: { [key: number]: string };
  classIds: number[] | null;
}

interface ParsedClassID {
  name: string;
  label: string;
}

const validator = (r: RuleType) => !!r.value;

const LogicBuilder: React.FC<LogicBuilderProps> = ({
  query,
  setQuery,
  parsedClassIDs,
  classIds,
}) => {
  const [fields, setFields] = useState<Field[]>([]);

  useEffect(() => {
    const formattedClasses = Object.values(parsedClassIDs).map((value) => ({
      name: value,
      label: value,
    }));

    // Check if classIds is not null before mapping
    const formattedClassIDs = classIds
      ? Array.from(new Set(classIds)).map((value) => ({
          name: value.toString(),
          label: value.toString(),
        }))
      : [];

    const fields = [
      {
        name: "class",
        label: "Class Name",
        operators: defaultOperators.filter((op) => op.name === "="),
        valueEditorType: "multiselect",
        values: formattedClasses,
      },
      {
        name: "classId",
        label: "Class ID",
        operators: defaultOperators.filter((op) => op.name === "="),
        valueEditorType: "multiselect",
        values: formattedClassIDs,
      },
      {
        name: "confLevel",
        label: "Confidence Level",
        operators: defaultOperators.filter((op) =>
          ["=", ">", "<", ">=", "<="].includes(op.name)
        ),
        inputType: "number",
        validator,
      },
      {
        name: "word",
        label: "Word",
        operators: defaultOperators.filter((op) =>
          ["contains"].includes(op.name)
        ),
        validator,
      },
      {
        name: "resultCount",
        label: "Result.count",
        operators: defaultOperators.filter((op) =>
          ["=", ">", "<", ">=", "<="].includes(op.name)
        ),
        inputType: "number",
        validator,
      },
    ] satisfies Field[];

    setFields(fields.map((o) => toFullOption(o)));
  }, [parsedClassIDs, classIds]);

  const handleOnQueryChange = (newQuery: any) => {
    console.log("Query changed:", newQuery);
    setQuery(newQuery);
  };

  return (
    <QueryBuilderDnD dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}>
      <ThemeProvider theme={muiTheme}>
        <QueryBuilderMaterial>
          <div style={{ width: "100%", overflowX: "auto" }}>
            <QueryBuilder
              fields={fields}
              query={query}
              onQueryChange={handleOnQueryChange}
              resetOnFieldChange={false}
              controlClassnames={{ queryBuilder: "queryBuilder-branches" }}
            />
          </div>
        </QueryBuilderMaterial>
      </ThemeProvider>
    </QueryBuilderDnD>
  );
};

export default LogicBuilder;
