import React from "react";
import Box from "@mui/material/Box";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

export function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          {...props}
          sx={{
            height: 10,
            borderRadius: 5,
            "& .MuiLinearProgress-bar": {
              borderRadius: 5,
            },
          }}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export function LinearProgressTopBar(
  props: LinearProgressProps & { value: number; color?: string }
) {
  const { color = "primary", value, ...restProps } = props;

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        backgroundColor: "background.paper",
        zIndex: 1000, // Ensures it stays above other content
      }}
    >
      <Box sx={{ position: "relative", width: "100%" }}>
        <LinearProgress
          variant="determinate"
          {...restProps}
          value={value}
          color={color}
          sx={{
            height: 20,
            "& .MuiLinearProgress-bar": {
              backgroundColor: color,
            },
          }}
        />
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            position: "absolute",
            top: "25%",
            left: "50%",
            transform: "translateX(-50%)",
            lineHeight: "10px",
          }}
        >
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
