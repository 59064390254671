import React, { useState, useEffect } from "react";
import axios, { AxiosResponse } from "axios";

import {
  Card,
  Typography,
  Slider,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import type { SelectChangeEvent } from "@mui/material/Select";

interface Project {
  project_id: number;
  project_name: string;
}

interface ProjectConfigProps {
  projectName: string;
  setProjectName: React.Dispatch<React.SetStateAction<string>>;
  setProjectID: React.Dispatch<React.SetStateAction<string>>;
  setConfidence: React.Dispatch<React.SetStateAction<number>>;
}

const ProjectConfiguration: React.FC<ProjectConfigProps> = ({
  setConfidence,
  projectName,
  setProjectName,
  setProjectID,
}) => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [confidenceLevel, setConfidenceLevel] = useState<number>(0.5);

  useEffect(() => {
    axios
      .get<{ data: Project[] }>("/sol_server/project/all_projects")
      .then((response: AxiosResponse<{ data: Project[] }>) => {
        console.log(response.data);
        setProjects(response.data.data);
        if (projectName === "") {
          setProjectName("Test_Nesspresso");
          const infProject = response.data.data.find(
            (project) => project.project_name === "Test_Nesspresso"
          );
          if (infProject) {
            setProjectID(infProject.project_id.toString());
          }
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  const handleProjectChange = (event: SelectChangeEvent<string>) => {
    setProjectName(event.target.value as string);
    const infProject = projects.find(
      (project) => project.project_name === event.target.value
    );
    if (infProject) {
      setProjectID(infProject.project_id.toString());
    }
  };

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setConfidence(newValue as number);
    setConfidenceLevel(newValue as number);
  };

  return (
    <Card sx={{ padding: "24px" }}>
      <Typography variant="h5" fontWeight="bold" mb={2}>
        Project Configuration
      </Typography>
      <FormControl fullWidth>
        <InputLabel id="project-select-label">Select Project</InputLabel>
        <Select
          labelId="project-select-label"
          id="project-select"
          value={projectName}
          label="Select Project"
          onChange={handleProjectChange}
        >
          {projects.map((project) => (
            <MenuItem key={project.project_id} value={project.project_name}>
              {project.project_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box mb={2} mt={4}>
        <Typography variant="body2" color="text.secondary" mb={1}>
          Minimum Confidence Level: <span>{confidenceLevel.toFixed(2)}</span>
        </Typography>
        <Slider
          id="confidence-level"
          value={confidenceLevel}
          min={0}
          max={1}
          step={0.01}
          onChange={handleSliderChange}
          aria-labelledby="confidence-level"
        />
      </Box>
    </Card>
  );
};

export default ProjectConfiguration;
