import React from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Box,
} from "@mui/material";

interface CardPanelProps {
  children?: React.ReactNode;
}

const CardPanel: React.FC<CardPanelProps> = ({ children }) => {
  return (
    <Card
      sx={{
        mx: "auto",
        mt: 2,
        p: 2,
        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
        borderRadius: 2,
      }}
    >
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default CardPanel;
