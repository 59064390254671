import React, { useState } from "react";
import Box from "@mui/material/Box";

interface ImageViewDetailsProps {
  selectedImage: string;
  rois: number[][];
  classIds: number[];
}

const ImageViewDetails: React.FC<ImageViewDetailsProps> = ({
  selectedImage,
  rois,
  classIds,
}) => {
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

  const handleImageLoad = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    const { naturalWidth, naturalHeight } = event.currentTarget;
    setImageSize({ width: naturalWidth, height: naturalHeight });
    // console.log(`Image loaded with size: ${naturalWidth}x${naturalHeight}`);
  };

  const colors = [
    "red",
    "green",
    "blue",
    "yellow",
    "purple",
    "orange",
    "pink",
    "brown",
    "cyan",
    "magenta",
  ];

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box sx={{ width: "90%", mt: 2 }}>
        <div style={{ position: "relative", display: "inline-block" }}>
          <img
            src={selectedImage}
            alt="Example"
            style={{ width: "100%", height: "auto" }}
            onLoad={handleImageLoad}
          />
          {rois.map((roi, index) => {
            // console.log(`ROI ${index}:`, roi);
            const [topLeftX, topLeftY, bottomRightX, bottomRightY] = roi;
            const width = bottomRightX - topLeftX;
            const height = bottomRightY - topLeftY;
            const color = colors[classIds[index] % colors.length]; // Get color based on class ID

            return (
              <Box
                key={index}
                sx={{
                  position: "absolute",
                  top: `${(topLeftY / imageSize.height) * 100}%`,
                  left: `${(topLeftX / imageSize.width) * 100}%`,
                  width: `${(width / imageSize.width) * 100}%`,
                  height: `${(height / imageSize.height) * 100}%`,
                  border: `2px solid ${color}`,
                  boxSizing: "border-box",
                }}
              />
            );
          })}
        </div>
      </Box>
    </Box>
  );
};

export default ImageViewDetails;
