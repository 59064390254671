import React, {
  useEffect,
  useRef,
  useState,
  MouseEvent,
  TouchEvent,
} from "react";
import {
  Button,
  useMediaQuery,
  createTheme,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import CropIcon from "@mui/icons-material/Crop";
import ClearIcon from "@mui/icons-material/Clear";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import ReplayIcon from "@mui/icons-material/Replay";

interface Point {
  x: number;
  y: number;
}

interface CameraCaptureProps {
  onImageChange?: (imageUrl: string) => void; // Callback to handle captured image
  onRoiSelected: (roi: [number, number, number, number] | null) => void;
  initialRoi?: [number, number, number, number];
}

const theme = createTheme();

const CameraCapture: React.FC<CameraCaptureProps> = ({
  onImageChange,
  onRoiSelected,
  initialRoi,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const [openCaptureDialog, setOpenCaptureDialog] = useState(false);

  const [capturedImage, setCapturedImage] = useState<string | null>(null);
  const [videoSize, setVideoSize] = useState<[number, number] | null>(null);

  const [isSelecting, setIsSelecting] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [captureMode, setCaptureMode] = useState(true);

  const [roi, setRoi] = useState<[Point, Point, Point, Point] | null>(null); // ROI points
  const [draggingPoint, setDraggingPoint] = useState<number | null>(null); // Currently dragging point

  const [currentDeviceId, setCurrentDeviceId] = useState<string | null>(null);
  const [availableDevices, setAvailableDevices] = useState<MediaDeviceInfo[]>(
    []
  );

  useEffect(() => {
    const getAvailableDevices = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ video: true });
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );
        console.log(videoDevices);
        setAvailableDevices(videoDevices);
        setCurrentDeviceId(videoDevices[0]?.deviceId || null);
      } catch (err) {
        console.error("Error getting video devices", err);
      }
    };

    getAvailableDevices();
  }, []);

  useEffect(() => {
    const startCamera = async (deviceId?: string) => {
      try {
        const constraints: MediaStreamConstraints = {
          video: {
            deviceId: deviceId ? { exact: deviceId } : undefined,
          },
        };
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.onloadedmetadata = () => {
            adjustVideoAndCanvasSize();
          };
        }
      } catch (err) {
        console.error("Error accessing the camera", err);
        handleSwitchCamera();
      }
    };

    if (openCaptureDialog && captureMode && currentDeviceId) {
      startCamera(currentDeviceId);
    }
  }, [captureMode, currentDeviceId, openCaptureDialog]);

  const adjustVideoAndCanvasSize = () => {
    if (canvasRef.current && videoRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      const video = videoRef.current;

      if (canvas && ctx && video.videoWidth && video.videoHeight) {
        // setVideoSize([video.videoWidth, video.videoHeight]);
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight * 0.7;
        const videoAspectRatio = video.videoWidth / video.videoHeight;
        const windowAspectRatio = windowWidth / windowHeight;

        let videoWidth: number;
        let videoHeight: number;

        if (windowAspectRatio > videoAspectRatio) {
          videoHeight = windowHeight;
          videoWidth = videoHeight * videoAspectRatio;
        } else {
          videoWidth = windowWidth;
          videoHeight = videoWidth / videoAspectRatio;
        }

        canvas.width = videoWidth;
        canvas.height = videoHeight;

        setVideoSize([videoWidth, videoHeight]);

        if (!roi && initialRoi) {
          const scaleX = canvas.width / video.videoWidth;
          const scaleY = canvas.height / video.videoHeight;
          const [x, y, width, height] = initialRoi;
          setRoi([
            {
              x: x * scaleX,
              y: y * scaleY,
            },
            {
              x: (x + width) * scaleX,
              y: y * scaleY,
            },
            {
              x: x * scaleX,
              y: (y + height) * scaleY,
            },
            {
              x: (x + width) * scaleX,
              y: (y + height) * scaleY,
            },
          ]);
        }
      }
    }
  };

  useEffect(() => {
    const draw = () => {
      if (canvasRef.current && videoRef.current) {
        const ctx = canvasRef.current.getContext("2d");
        const video = videoRef.current;

        if (ctx && video) {
          ctx.drawImage(
            video,
            0,
            0,
            canvasRef.current.width,
            canvasRef.current.height
          );

          if (roi) {
            const [topLeft, topRight, bottomLeft, bottomRight] = roi;
            ctx.strokeStyle = "red";
            ctx.lineWidth = 2;
            ctx.strokeRect(
              topLeft.x,
              topLeft.y,
              topRight.x - topLeft.x,
              bottomLeft.y - topLeft.y
            );

            const drawHandle = (point: Point) => {
              ctx.beginPath();
              ctx.arc(point.x, point.y, 5, 0, 2 * Math.PI);
              ctx.fillStyle = "white";
              ctx.fill();
              ctx.strokeStyle = "red";
              ctx.stroke();
            };

            drawHandle(topLeft);
            drawHandle(topRight);
            drawHandle(bottomLeft);
            drawHandle(bottomRight);
          }
        }
      }
    };

    if (captureMode) {
      const interval = setInterval(draw, 30);
      return () => clearInterval(interval);
    }
  }, [roi, captureMode]);

  useEffect(() => {
    const draw = () => {
      if (canvasRef.current) {
        const canvas = canvasRef.current;
        const ctx = canvasRef.current.getContext("2d");

        if (ctx && capturedImage) {
          const image = new Image();
          image.src = capturedImage;
          image.onload = () => {
            ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
            if (roi) {
              const [topLeft, topRight, bottomLeft, bottomRight] = roi;
              ctx.strokeStyle = "red";
              ctx.lineWidth = 2;
              ctx.strokeRect(
                topLeft.x,
                topLeft.y,
                topRight.x - topLeft.x,
                bottomLeft.y - topLeft.y
              );

              const drawHandle = (point: Point) => {
                ctx.beginPath();
                ctx.arc(point.x, point.y, 5, 0, 2 * Math.PI);
                ctx.fillStyle = "white";
                ctx.fill();
                ctx.strokeStyle = "red";
                ctx.stroke();
              };

              drawHandle(topLeft);
              drawHandle(topRight);
              drawHandle(bottomLeft);
              drawHandle(bottomRight);
            }
          };
        }
      }
    };

    if (!captureMode) {
      draw();
    }
  }, [roi, captureMode]);

  const getRelativePosition = (x: number, y: number) => {
    const canvas = canvasRef.current;
    const rect = canvas?.getBoundingClientRect();
    if (rect && canvas) {
      const scaleX = canvas.width / rect.width;
      const scaleY = canvas.height / rect.height;
      return {
        x: (x - rect.left) * scaleX,
        y: (y - rect.top) * scaleY,
      };
    }
    return { x: 0, y: 0 };
  };

  const updateRoi = (
    start: Point,
    end: Point
  ): [Point, Point, Point, Point] => {
    return [
      { x: Math.min(start.x, end.x), y: Math.min(start.y, end.y) },
      { x: Math.max(start.x, end.x), y: Math.min(start.y, end.y) },
      { x: Math.min(start.x, end.x), y: Math.max(start.y, end.y) },
      { x: Math.max(start.x, end.x), y: Math.max(start.y, end.y) },
    ];
  };

  const handleMouseDown = (e: MouseEvent<HTMLCanvasElement>) => {
    if (isSelecting) {
      setIsEditing(true);
      const position = getRelativePosition(e.clientX, e.clientY);
      if (roi) {
        const [topLeft, topRight, bottomLeft, bottomRight] = roi;
        const points = [topLeft, topRight, bottomLeft, bottomRight];
        const pointIndex = points.findIndex(
          (point) =>
            Math.abs(point.x - position.x) < 10 &&
            Math.abs(point.y - position.y) < 10
        );
        if (pointIndex !== -1) {
          setDraggingPoint(pointIndex);
          return;
        }
      }
      setRoi([position, position, position, position]);
    }
  };

  const handleMouseMove = (e: MouseEvent<HTMLCanvasElement>) => {
    if (isSelecting && roi && isEditing) {
      const position = getRelativePosition(e.clientX, e.clientY);
      if (draggingPoint !== null) {
        let newRoi = [...roi];
        switch (draggingPoint) {
          case 0:
            newRoi = updateRoi(position, roi[3]);
            break;
          case 1:
            newRoi = updateRoi(
              { x: roi[2].x, y: position.y },
              { x: position.x, y: roi[2].y }
            );
            break;
          case 2:
            newRoi = updateRoi(
              { x: position.x, y: roi[1].y },
              { x: roi[1].x, y: position.y }
            );
            break;
          case 3:
            newRoi = updateRoi(roi[0], position);
            break;
        }
        setRoi(newRoi as [Point, Point, Point, Point]);
      } else {
        setRoi(updateRoi(roi[0], position));
      }
    }
  };

  const handleMouseUp = () => {
    if (isSelecting) {
      setDraggingPoint(null);
      setIsEditing(false);

      if (roi) {
        const [topLeft, , , bottomRight] = roi;
        const canvas = canvasRef.current;

        if (canvas && videoSize) {
          const scaleX = videoSize[0] / canvas.width;
          const scaleY = videoSize[1] / canvas.height;

          // Calculate the top-left X, top-left Y, width, and height with respect to the original image dimensions
          const originalTopLeftX = topLeft.x * scaleX;
          const originalTopLeftY = topLeft.y * scaleY;
          const width = (bottomRight.x - topLeft.x) * scaleX;
          const height = (bottomRight.y - topLeft.y) * scaleY;

          const originalRoi = [
            originalTopLeftX,
            originalTopLeftY,
            width,
            height,
          ] as [number, number, number, number];

          onRoiSelected(originalRoi);
          console.log("ROI:", originalRoi);
          console.log("Video:", videoSize);
        }
      }
    }
  };

  const handleTouchStart = (e: TouchEvent<HTMLCanvasElement>) => {
    handleMouseDown(e.touches[0] as any);
  };

  const handleTouchMove = (e: TouchEvent<HTMLCanvasElement>) => {
    handleMouseMove(e.touches[0] as any);
  };

  const handleTouchEnd = () => {
    handleMouseUp();
  };

  const handleToggleROI = () => {
    setIsSelecting(!isSelecting);
  };

  const handleClearROI = () => {
    setRoi(null);
    setDraggingPoint(null);
    onRoiSelected(null);
  };

  const handleCapture = () => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      const video = videoRef.current;

      if (ctx && video) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        console.log("Captue Size:", canvas.width, canvas.height);

        const imageData = canvas.toDataURL("image/png");
        if (onImageChange) {
          onImageChange(imageData);
        }

        setCapturedImage(imageData);
        setCaptureMode(false);
      }
    }
  };

  const handleRetake = () => {
    setCaptureMode(true);
    setCapturedImage(null);
  };

  const handleSwitchCamera = () => {
    if (availableDevices.length > 1) {
      const currentIndex = availableDevices.findIndex(
        (device) => device.deviceId === currentDeviceId
      );
      const nextIndex = (currentIndex + 1) % availableDevices.length;
      setCurrentDeviceId(availableDevices[nextIndex].deviceId);
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        component="span"
        onClick={() => setOpenCaptureDialog(true)}
      >
        <PhotoCameraIcon />
      </Button>

      {/* Dialog for Camera Capture */}
      <Dialog
        open={openCaptureDialog}
        onClose={() => setOpenCaptureDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Capture Image</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              overflow: "hidden",
            }}
          >
            <video ref={videoRef} style={{ display: "none" }} autoPlay />
            <canvas
              ref={canvasRef}
              style={{
                width: "100%",
                maxHeight: "70vh",
              }}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          {/* <Button
                variant="contained"
                color={isSelecting ? "secondary" : "primary"}
                onClick={handleToggleROI}
              >
                <CropIcon sx={{ fontSize: "2rem" }} />
              </Button> */}
          <Button variant="contained" color="error" onClick={handleClearROI}>
            <ClearIcon />
          </Button>
          {captureMode ? (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCapture}
              >
                <PhotoCameraIcon />
              </Button>
              <Button variant="contained" onClick={handleSwitchCamera}>
                <CameraswitchIcon />
              </Button>
            </>
          ) : (
            <Button variant="contained" color="primary" onClick={handleRetake}>
              <ReplayIcon />
            </Button>
          )}
          <Button onClick={() => setOpenCaptureDialog(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CameraCapture;
