import React, { useState, useEffect } from "react";

import axios, { AxiosResponse } from "axios";
import {
  Grid,
  Box,
  Button,
  Container,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import type { RuleGroupType } from "react-querybuilder";

import TabPanel from "../components/common/TabPanel";
import CardPanel from "../components/common/CardPanel";
import { LinearProgressTopBar } from "../components/common/ProgressBar";
import { ErrorDialog } from "../components/common/ErrorDialog";
import JsonDialogBox from "../components/common/JsonDialog";

import ProjectConfiguration from "../components/home/ProjectConfiguration";
import ImageProcess from "../components/home/ImageProcess";
import ClassIDConfig from "../components/home/ClassIdConfig";
import ClassIDFilter from "../components/home/ClassIDFilter";
import CountingMode from "../components/home/CountingMode";
import OkNgRule from "../components/home/OkNgRule";
import DetectionResults from "../components/home/DetectionResults";
import OkNgResult from "../components/home/OkNgResult";
import LogicBuilder from "../components/logic/LogicBuilder";

interface Object {
  id: number;
  classId: number;
  class: string;
  confLevel: number;
  word: string;
  roi: number[];
}

const initialQuery: RuleGroupType = { combinator: "and", rules: [] };

const Home: React.FC = () => {
  const [tab, setTab] = useState(0);

  const [projectName, setProjectName] = useState<string>("");
  const [projectID, setProjectID] = useState<string>("");

  const [croppedImageFile, setCroppedImageFile] = useState<File | null>(null);

  const [classIDConfig, setClassIDConfig] = useState<string>("");
  const [parsedClassIDs, setParsedClassIDs] = useState<Record<number, string>>(
    {}
  );

  const [confidence, setConfidenceLevel] = useState<number>(0.5);

  const [rois, setRois] = useState<number[][] | null>(null);
  const [classIds, setClassIds] = useState<number[] | null>(null);
  const [confLevels, setConfLevels] = useState<number[] | null>(null);
  const [responseData, setResponseData] = useState<string>("");
  const [resultData, setResultData] = useState<Object[] | null>(null);

  const [infError, setInfError] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);

  const [query, setQuery] = useState(initialQuery);
  const [resultBool, setResultBool] = useState<string>("Failed");

  const [reportDialogOpen, setReportDialogOpen] = useState<boolean>(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);
  const [timeSpent, setTimeSpent] = useState<number | null>(null);

  useEffect(() => {
    const scrollToBottom = () => {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    };

    console.log(query.rules.length);
    if (query.rules.length > 0) {
      scrollToBottom();
    }
  }, [query]);

  useEffect(() => {
    const mergeArrays = (
      array1: Object[],
      array2: Object[],
      operation: string
    ): Object[] => {
      if (operation === "and") {
        // Find common objects by id
        return array1.filter((obj1) =>
          array2.some((obj2) => obj1.id === obj2.id)
        );
      } else if (operation === "or") {
        // Combine arrays and remove duplicates by id
        const combined = [...array1, ...array2];
        const uniqueById = Array.from(new Set(combined.map((obj) => obj.id)))
          .map((id) => combined.find((obj) => obj.id === id))
          .filter((obj): obj is Object => obj !== undefined);

        return uniqueById;
      }

      return [];
    };

    const evaluateCondition = (
      objValue: number | string,
      ruleValue: number | string,
      operator: string
    ): boolean => {
      switch (operator) {
        case "=":
          return objValue == ruleValue;
        case "contains":
          if (typeof objValue === "string" && typeof ruleValue === "string") {
            return objValue.includes(ruleValue) || ruleValue.includes(objValue);
          }
          return false;

        case ">":
          return typeof objValue === "number" && typeof ruleValue === "number"
            ? objValue > ruleValue
            : false;
        case "<":
          return typeof objValue === "number" && typeof ruleValue === "number"
            ? objValue < ruleValue
            : false;
        case ">=":
          return typeof objValue === "number" && typeof ruleValue === "number"
            ? objValue >= ruleValue
            : false;
        case "<=":
          return typeof objValue === "number" && typeof ruleValue === "number"
            ? objValue <= ruleValue
            : false;
        default:
          return false;
      }
    };

    const evaluateLogical = (
      boolArray: boolean[],
      operator: string // Restricting operator to specific string literals
    ): boolean => {
      if (operator === "and") {
        return boolArray.every((value) => value === true);
      } else if (operator === "or") {
        return boolArray.some((value) => value === true);
      }
      return false; // or throw an error if appropriate
    };

    const processQueryRule = (ruleGroup: RuleGroupType): boolean => {
      let resultCountTH = -1;
      let resultCountOP = "";
      let result: Object[] = [];
      const logicStatus: boolean[] = [];

      if (resultData) {
        result = resultData;
      }

      ruleGroup.rules.forEach((rule) => {
        if ("rules" in rule) {
          // If the rule is a group, recursively process it
          logicStatus.push(processQueryRule(rule as RuleGroupType));
        } else {
          if (rule.field === "resultCount") {
            resultCountTH = parseInt(rule.value);
            resultCountOP = rule.operator;
          } else {
            if (resultData && result) {
              const filteredObjects = resultData.filter((obj) => {
                const field = rule.field as keyof typeof obj;
                if (rule.field === "class" || rule.field === "classId") {
                  const ruleValues = rule.value.split(",");
                  for (const eachValue of ruleValues) {
                    const trimmedValue = eachValue.trim();
                    if (
                      evaluateCondition(
                        obj[field] as number | string,
                        trimmedValue,
                        rule.operator
                      )
                    ) {
                      return true;
                    }
                  }
                  return false;
                } else if (rule.field === "word") {
                  return evaluateCondition(
                    obj[field] as number | string,
                    rule.value,
                    rule.operator
                  );
                } else if (rule.field === "confLevel") {
                  return evaluateCondition(
                    obj[field] as number | string,
                    parseFloat(rule.value),
                    rule.operator
                  );
                } else return false;
              });

              result = mergeArrays(
                result,
                filteredObjects,
                ruleGroup.combinator
              );
            }
          }
        }
      });

      if (resultCountTH > -1) {
        console.log("Result:", result);
        logicStatus.push(
          evaluateCondition(result.length, resultCountTH, resultCountOP)
        );
      }

      return evaluateLogical(logicStatus, ruleGroup.combinator);
    };

    if (resultData) {
      const resBool = processQueryRule(query);
      console.log("Final Result: ", resBool);
      if (resBool) {
        setResultBool("TRUE");
      } else {
        setResultBool("FALSE");
      }
    }
  }, [query, resultData]);

  useEffect(() => {
    if (resultData) {
      const updatedResultArray = resultData.map((item) => ({
        ...item,
        class: parsedClassIDs[item.classId] || "Unknown",
      }));

      setResultData(updatedResultArray);
    }
  }, [parsedClassIDs]);

  const clearData = () => {
    setRois(null);
    setClassIds(null);
    setResultData(null);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const handleInference = () => {
    if (croppedImageFile) {
      const fetchData = async () => {
        try {
          const startTime = Date.now();

          clearData();
          setInfError(false);
          let projectURL = `/sol_server/project/${projectID}`;
          const resProject = await axios.get(projectURL);
          const projectDetails = resProject.data.data[0];
          console.log(projectDetails);
          setProgress(20);

          const regData = {
            username: "guest",
            project_id: projectID,
            state_id: projectDetails.state_id,
          };

          const resRegister = await axios.post(
            "/sol_server/job/trigger",
            regData
          );

          const regDetails = resRegister.data.data;
          console.log(regDetails);
          setProgress(50);

          const infHeader = {
            "Content-Type": "multipart/form-data",
            "Tool-Name": projectDetails.tool,
            "Model-Id": projectDetails.model.model_id,
            "Job-Id": regDetails.job_id,
            "State-Id": projectDetails.state_id,
            threshold: confidence,
            split_text: 0,
          };

          const infImageData = new FormData();
          infImageData.append("files", croppedImageFile);

          const infResponse = await axios.post(
            "/sol_server/inference",
            infImageData,
            {
              headers: infHeader,
            }
          );

          const infData = infResponse.data.data;
          setResponseData(JSON.stringify(infResponse.data, null, 2));
          // console.log(infData);
          setProgress(100);

          if (infData.class_ids.length == 0) {
            setInfError(true);
            setProgress(100);
            setErrorDialogOpen(true);
            return;
          }

          setRois(infData.rois);
          setClassIds(infData.class_ids);
          setConfLevels(infData.scores);

          const resultArray: Object[] = infData.rois.map(
            (roi: number[], index: number) => ({
              id: index,
              classId: infData.class_ids[index],
              class: parsedClassIDs[infData.class_ids[index]] || "Unknown",
              confLevel: infData.scores[index],
              word: infData.word[index],
              roi,
            })
          );

          setResultData(resultArray);

          const endTime = Date.now();
          const timeElapsed = endTime - startTime;
          setTimeSpent(timeElapsed);
        } catch (error) {
          console.error("Error:", error);
        }
      };

      fetchData();
    }
  };

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <CardPanel>
            <ImageProcess
              rois={rois}
              classIds={classIds}
              croppedImageFile={croppedImageFile}
              setCroppedImageFile={setCroppedImageFile}
              clearData={clearData}
            />
          </CardPanel>
          <Container sx={{ mt: 2, mb: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                color="success"
                sx={{
                  fontWeight: "bold",
                  py: 2,
                  px: 4,
                  borderRadius: "8px",
                  mr: 2, // Add margin-right to create space between buttons
                }}
                onClick={handleInference}
              >
                Start
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{ fontWeight: "bold", py: 2, px: 4, borderRadius: "8px" }}
                onClick={() => setReportDialogOpen(true)}
              >
                Result
              </Button>
              {timeSpent !== null && (
                <Box sx={{ ml: 2, textAlign: "center" }}>
                  <Typography variant="body2">Time spent:</Typography>
                  <Typography variant="h6">{timeSpent} ms</Typography>
                </Box>
              )}
            </Box>
          </Container>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={tab}
              onChange={handleTabChange}
              aria-label="simple tabs example"
            >
              <Tab label="Settings" />
              <Tab label="Results" />
            </Tabs>
            <TabPanel value={tab} index={0}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <ProjectConfiguration
                    projectName={projectName}
                    setProjectName={setProjectName}
                    setProjectID={setProjectID}
                    setConfidence={setConfidenceLevel}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <ClassIDConfig
                    classIDConfig={classIDConfig}
                    setClassIDConfig={setClassIDConfig}
                    setParsedClassIDs={setParsedClassIDs}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <Grid item xs={12} lg={12}>
                <DetectionResults
                  parsedClassIDs={parsedClassIDs}
                  classIds={classIds}
                  confLevels={confLevels}
                  items={resultData}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <Container sx={{ mt: 2, mb: 2 }}>
                  {query.rules.length > 0 && resultData && (
                    <OkNgResult status={resultBool} />
                  )}
                </Container>
              </Grid>
            </TabPanel>
          </Box>
        </Grid>
      </Grid>
      <Container
        sx={{
          mt: 2,
          mb: 6,
        }}
      >
        <LogicBuilder
          query={query}
          setQuery={setQuery}
          parsedClassIDs={parsedClassIDs}
          classIds={classIds}
        />
      </Container>

      <LinearProgressTopBar
        value={progress}
        color={infError ? "error" : "primary"}
      />
      <ErrorDialog
        open={errorDialogOpen}
        onClose={() => setErrorDialogOpen(false)}
      />
      <JsonDialogBox
        open={reportDialogOpen}
        onClose={() => setReportDialogOpen(false)}
        jsonString={responseData}
      />
    </Container>
  );
};

export default Home;
