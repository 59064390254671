import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

interface ErrorDialogProps {
  open: boolean;
  onClose: () => void;
}

export const ErrorDialog: React.FC<ErrorDialogProps> = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ErrorOutlineIcon sx={{ marginRight: 1, color: "red" }} />
          Error
        </Box>
      </DialogTitle>
      <DialogContent>
        <p>No Objects detected</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
