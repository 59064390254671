import React from "react";
import { Card, Typography, Box } from "@mui/material";

const OkNgResult: React.FC<{ status: string }> = ({ status }) => {
  return (
    <Card sx={{ padding: "24px" }}>
      <Typography variant="h5" fontWeight="bold" mb={2}>
        Logic Result
      </Typography>
      <Box
        id="ok-ng-display"
        sx={{
          backgroundColor:
            status === "Waiting for inference..."
              ? "gray"
              : status === "TRUE"
              ? "green"
              : "red",
          color: "white",
          textAlign: "center",
          fontSize: "24px",
          fontWeight: "bold",
          py: 2,
          borderRadius: "8px",
        }}
      >
        {status}
      </Box>
    </Card>
  );
};

export default OkNgResult;
